import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const HtmlContent = ({ data }) => {
  const { html } = data;
  return (
    <section className="section wrapper-container">
      <div className="container-fluid" dangerouslySetInnerHTML={{ __html: html.value }}></div>
    </section>
  );
};

export default withPreview(HtmlContent);
export const fragment = graphql`
  fragment JskHtmlContentFragment on LiferayJskHtmlContent {
    liferayFields {
      siteId
      articleId
    }
    html {
      value
    }
  }
`;

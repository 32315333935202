import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import Cta from "../elements/cta";
import withPreview from "../../utility/with-preview";

const PuzzleBox = ({ children, link }) => {
  return (
    <>
      {link ? (
        <Link className="caratteristiche-puzzle__container" to={link}>
          {children}
        </Link>
      ) : (
        children
      )}
    </>
  );
};

const CaratteristichePuzzle = ({ data }) => {
  const { title, titoloPuzzle, immagineSeparatore, cta } = data;

  return (
    <section className="section caratteristiche-puzzle wrapper-container">
      <div className="container">
        {title?.value && (
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="section__title" style={{ color: title.content?.colorTitle?.value }}>
                {title.value}
              </h2>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 d-flex flex-column flex-lg-row">
            {titoloPuzzle.map((item, i) => (
              <div className="caratteristiche-puzzle__box" key={i}>
                <PuzzleBox key={i} link={item.content.icon?.content?.pageLink?.value?.link}>
                  <div className="caratteristiche-puzzle__box-content">
                    {item.content?.icon?.node && (
                      <GatsbyImage
                        className="icon"
                        image={getImage(item.content.icon.node)}
                        alt="icon"
                      />
                    )}
                    <div
                      className="caratteristiche-puzzle__text"
                      style={{ color: item.content?.colorPuzzleTitle?.value }}
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    ></div>
                    <div
                      className="caratteristiche-puzzle__desc"
                      dangerouslySetInnerHTML={{ __html: item.content?.sottotitolo?.value }}
                    ></div>
                  </div>
                  {immagineSeparatore?.node && (
                    <span
                      className="caratteristiche-puzzle__box-separator"
                      style={{ backgroundImage: `url(${immagineSeparatore.node?.publicURL})` }}
                    ></span>
                  )}
                </PuzzleBox>
              </div>
            ))}
          </div>
        </div>
        <Cta cta={cta} className="mt-5" />
      </div>
    </section>
  );
};

export default withPreview(CaratteristichePuzzle);

export const fragment = graphql`
  fragment CaratteristichePuzzleFragment on LiferayCaratteristichePuzzle {
    liferayFields {
      siteId
      articleId
    }
    titoloPuzzle: titolo {
      value
      content {
        sottotitolo {
          value
        }
        icon {
          node {
            gatsbyImageData(formats: PNG, width: 100, layout: FIXED)
          }
          content {
            pageLink {
              value {
                ... on LiferayJskLayoutPaginaProdotto {
                  link
                }
                ... on LiferayJskLayoutPaginaHub {
                  link
                }
                ... on LiferayJskLayoutPaginaGenerica {
                  link
                }
                ... on LiferayJskLayoutLandingOfferte {
                  link
                }
              }
            }
          }
        }
        colorPuzzleTitle {
          value
        }
      }
    }
    title {
      value
      content {
        colorTitle {
          value
        }
      }
    }
    immagineSeparatore {
      node {
        publicURL
      }
    }
    cta {
      value
      content {
        colorebottone {
          value
        }
        ctaLink {
          value
        }
      }
    }
    liferayFields {
      siteId
      articleId
    }
  }
`;

import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import classNames from "classnames";
import Slider from "react-slick";
import Cta from "../elements/cta";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import { isFlusso, getProductName } from "../../utility/config-flussi-switch-in";
import parseModaleComponent from "../../utility/parseModaleComponent";

const ScopriDipiu = ({ data }) => {
  const { model, title, subtitle, twoColumns, boxTitle, infoBoxesLink, cta } = data;
  const modello = model?.value?.[0] || "Scopri di più (restyle)";

  const settings = {
    arrows: false,
    slidesPerRow: 1,
    rows: boxTitle?.length,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          className: "center",
          centerMode: false,
          infinite: false,
          slidesToShow: 1,
          rows: 1,
          speed: 500,
          swipe: true,
          dots: true,
          adaptiveHeight: false,
        },
      },
    ],
  };

  const getSlideIcon = (item) => {
    return withArtDirection(getImage(item.content.boxImage.node), [
      {
        media: "(max-width: 768px)",
        image: getImage(item.content.boxImage.nodeMobile),
      },
    ]);
  };

  const startFlusso = (productName) => {
    const fluxEvent = new CustomEvent("start-flusso-switch-in", {
      detail: {
        productName,
      },
    });
    document.dispatchEvent(fluxEvent);
  };

  const BoxVantaggio = ({ item, singleBox }) => {
    return (
      <div
        className={classNames("box-vantaggio d-flex align-items-center", {
          "box-vantaggio--modello-scopri-di-piu flex-column flex-md-row":
            modello === "Scopri di più",
        })}
      >
        {item.content?.boxImage?.node && (
          <GatsbyImage
            image={getSlideIcon(item)}
            className="box-vantaggio__img flex-shrink-0"
            alt={""}
          />
        )}
        <div
          className={classNames("box-vantaggio__text flex-grow-1 text-left", singleBox && "px-0")}
        >
          <div
            className="box-vantaggio__title"
            style={{ color: item.content?.colorBoxTitle?.value }}
          >
            {item.value}
          </div>
          {item.content?.boxContent?.value && (
            <div className="box-vantaggio__desc text-left">
              {parseModaleComponent(item.content.boxContent.value)}
            </div>
          )}
          {item.content?.readMoreLink?.value && item.content.readMoreLink.value !== "#" && (
            <Link
              to={item.content.readMoreLink.value}
              title="Leggi tutto"
              className="box-vantaggio__link"
              target="_blank"
              style={{ fontSize: "16px" }}
            >
              {infoBoxesLink?.value}
            </Link>
          )}
        </div>
      </div>
    );
  };

  return (
    <section className="section scopri-di-piu">
      <div className="wrapper-container">
        <div className="container-fluid">
          {title?.value && (
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="section__title" style={{ color: title.content?.colorTitle?.value }}>
                  {title.value}
                </h2>
              </div>
            </div>
          )}
          {subtitle?.value && (
            <div className="row">
              <div
                className={classNames("col-md-12", {
                  "text-left": subtitle.content?.subtitlePosition?.value?.[0] === "align-left",
                  "text-center":
                    subtitle.content?.subtitlePosition?.value?.[0] === "align-center" ||
                    !subtitle.content?.subtitlePosition?.value?.length,
                  "text-right": subtitle.content?.subtitlePosition?.value?.[0] === "align-right",
                })}
              >
                <h3 className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle.value }} />
              </div>
            </div>
          )}
          {boxTitle?.length && (
            <div className="row px-md-3">
              {modello === "Scopri di più" ? (
                <div className="col-12">
                  <Slider {...settings} className="box-vantaggio__slider">
                    {boxTitle.map((item, i) => (
                      <div key={i}>
                        <BoxVantaggio item={item} singleBox={boxTitle?.length === 1} />
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : (
                boxTitle.map((item, i) => (
                  <div
                    className={classNames("col-12 p-md-0 p-auto", {
                      "col-md-6": !!twoColumns?.value,
                      "px-0 px-md-3": !twoColumns?.value || boxTitle.length % 2 !== 0,
                    })}
                    key={i}
                  >
                    <BoxVantaggio item={item} />
                  </div>
                ))
              )}
            </div>
          )}
          {modello === "Scopri di più" &&
            cta?.value &&
            cta?.content?.ctaLink?.value &&
            cta?.content?.ctaLink?.value !== "#" &&
            (isFlusso(cta.content.ctaLink.value) ? (
              <div className="row">
                <div className="col-md-12 text-center">
                  <button
                    title={cta.value}
                    className={classNames(
                      "cta-link",
                      cta.content.colorebottone?.value
                        ? `btn-color-${cta.content.colorebottone?.value}`
                        : ""
                    )}
                    onClick={() => startFlusso(getProductName(cta.content.ctaLink.value))}
                  >
                    {cta.value}
                  </button>
                </div>
              </div>
            ) : (
              <Cta cta={cta} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default withPreview(ScopriDipiu);
export const fragment = graphql`
  fragment ScopriDiPiuFragment on LiferayScopriDiPiu {
    liferayFields {
      siteId
      articleId
    }
    model {
      value
    }
    title {
      value
      content {
        colorTitle {
          value
        }
      }
    }
    subtitle {
      value
      content {
        subtitlePosition {
          value
        }
      }
    }
    twoColumns {
      value
    }
    boxTitle {
      value
      content {
        colorBoxTitle {
          value
        }
        boxImage {
          node {
            gatsbyImageData(formats: PNG, width: 100, layout: FIXED)
          }
          nodeMobile: node {
            gatsbyImageData(width: 50, layout: FIXED)
          }
          content {
            linkImg {
              value
            }
          }
        }
        boxContent {
          value
        }
        readMoreLink {
          value
        }
      }
    }
    infoBoxesLink {
      value
    }
    cta {
      value
      content {
        colorebottone {
          value
        }
        ctaLink {
          value
        }
      }
    }
  }
`;

import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import { handleContentAccessibility } from "../../utility/accessibility";

const Intro = ({ data }) => {
  const { /*titolo, sottotitolo,*/ intro } = data;
  return (
    <section className="section intro wrapper-container">
      <div className="container-fluid">
        {/*{titolo?.value && (
          <div className="row">
            <div className="col-md-12">
              <h2 className="section__title">{titolo.value}</h2>
            </div>
          </div>
        )}
         {sottotitolo?.value && (
          <div className="row">
            <div className="col-md-12 pb-3">
              <h2
                className="intro__subtitle"
                dangerouslySetInnerHTML={{ __html: sottotitolo.value }}
              ></h2>
            </div>
          </div>
        )} */}
        <div className="row">
          <div className="col-sm-12 intro__content">{handleContentAccessibility(intro.value)}</div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(Intro);
export const fragment = graphql`
  fragment IntroFragment on LiferayIntro {
    liferayFields {
      siteId
      articleId
    }
    titolo {
      value
    }
    sottotitolo {
      value
    }
    intro {
      value
    }
  }
`;

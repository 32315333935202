import React from "react";
import { graphql } from "gatsby";
import SlideHeader from "../fasce/slide-header";
import WidgetTestuale from "../fasce/widget-testuale";
import CaratteristichePuzzle from "../fasce/caratteristiche-puzzle";
import Intro from "../fasce/intro";
import HubProdottoV2 from "../fasce/hub-prodotto-v2";
import HubGridItem from "../fasce/hub-grid-item";
import Hub1Item from "../fasce/hub1-item";
import BadgeWidget from "../fasce/badge-widget";
import Motivazioni from "../fasce/motivazioni";
import ElencoDocumenti from "../fasce/elenco-documenti";
import FormContatti from "../fasce/form-contatti";
import FlussoSwitchIn from "../fasce/flusso-switch-in";
import HubProdotto from "../fasce/hub-prodotto";
import HtmlContent from "../fasce/html-content";
import LandingHeaderComparatore from "../fasce/landing-header-comparatore";
import ScopriDipiu from "../fasce/scopri-di-piu";

const FasciaPaginaHub = ({ fascia, ...other }) => {
  switch (fascia.__typename) {
    case "LiferaySlideHeader":
      return <SlideHeader data={fascia} {...other} />;
    case "LiferayWidgetTestuale":
      return <WidgetTestuale data={fascia} {...other} />;
    case "LiferayCaratteristichePuzzle":
      return <CaratteristichePuzzle data={fascia} {...other} />;
    case "LiferayIntro":
      return <Intro data={fascia} {...other} />;
    case "LiferayHubProdottoV2":
      return <HubProdottoV2 data={fascia} {...other} />;
    case "LiferayHubGridItem":
      return <HubGridItem data={fascia} {...other} />;
    case "LiferayHub1Item":
      return <Hub1Item data={fascia} {...other} />;
    case "LiferayBadgeWidget":
      return <BadgeWidget data={fascia} {...other} />;
    case "LiferayMotivazioni":
      return <Motivazioni data={fascia} {...other} />;
    case "LiferayElencoDocumenti":
      return <ElencoDocumenti data={fascia} {...other} />;
    case "LiferayJskFormContatti":
      return <FormContatti data={fascia} {...other} />;
    case "LiferayJskFlussoSwitchIn":
      return <FlussoSwitchIn data={fascia} {...other} />;
    case "LiferayJskHubProdotto":
      return <HubProdotto data={fascia} {...other} />;
    case "LiferayJskHtmlContent":
      return <HtmlContent data={fascia} {...other} />;
    case "LiferayLandingHeaderComparatore":
      return <LandingHeaderComparatore data={fascia} {...other} />;
    case "LiferayScopriDiPiu":
      return <ScopriDipiu data={fascia} {...other} />;
    default:
      console.warn(`Cannot handle ${fascia.__typename} type for pagina hub`);
      return null;
  }
};

export default FasciaPaginaHub;
export const query = graphql`
  fragment FasciaPaginaHubFragment on LiferayWebContent {
    __typename
    ...SlideHeaderFragment
    ...WidgetTestualeFragment
    ...CaratteristichePuzzleFragment
    ...IntroFragment
    ...HubProdottoV2Fragment
    ...HubGridItemFragment
    ...Hub1ItemFragment
    ...BadgeWidgetFragment
    ...MotivazioniFragment
    ...ElencoDocumentiFragment
    ...FormContattiFragment
    ...JskFlussoSwitchInFragment
    ...JskHubProdottoFragment
    ...JskHtmlContentFragment
    ...LandingHeaderComparatoreFragment
    ...ScopriDiPiuFragment
  }
`;

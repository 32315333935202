import React from "react";
import { graphql } from "gatsby";
import HubItem from "../elements/hub-item";
import withPreview from "../../utility/with-preview";

const Hub1Item = ({ data }) => {
  const { titolo, immagine, pageLink } = data;

  return (
    <section className="hub1-item wrapper-container px-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <HubItem
              background={immagine?.node}
              titolo={titolo?.value}
              link={pageLink?.value?.link}
              bar={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(Hub1Item);
export const fragment = graphql`
  fragment Hub1ItemFragment on LiferayHub1Item {
    liferayFields {
      siteId
      articleId
    }
    titolo {
      value
    }
    immagine {
      node {
        gatsbyImageData(width: 1440)
      }
    }
    pageLink {
      value {
        ... on LiferayJskLayoutPaginaProdotto {
          link
        }
        ... on LiferayJskLayoutPaginaHub {
          link
        }
        ... on LiferayJskLayoutPaginaGenerica {
          link
        }
        ... on LiferayJskLayoutLandingOfferte {
          link
        }
      }
    }
  }
`;

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import Icon from "../elements/icon-svg";

const HubItem = ({ background, titolo, descr, link, bar }) => {
  return (
    <div className="hub-item">
      {background && (
        <GatsbyImage
          className="hub-item__background"
          image={getImage(background)}
          alt={titolo || "hub-item"}
        />
      )}
      <div className="hub-item__text d-flex flex-column justify-content-end">
        {bar && <div className="hub-item__bar" />}
        {titolo && <div className="hub-item__title" dangerouslySetInnerHTML={{ __html: titolo }} />}
        {descr && <div className="hub-item__descr" dangerouslySetInnerHTML={{ __html: descr }} />}
        <div className="mt-2">
          <Link to={link} className="hub-item__seeMore">
            <Icon
              name="long-arrow-right-solid"
              style={{ "--width": "18px", "--height": "21px" }}
              alt="Vai"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HubItem;

import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "../link";
import withPreview from "../../utility/with-preview";
import { dataLayerPush, dataLayerStr2Obj } from "../../utility/dataLayerUtils";

const BadgeBox = ({ link, newPage, useGa, children }) => {
  /* <#if badge_link.getData()?contains("ezzing")>
        $( document ).ready(function() {
            var gaId = ga.getAll()[0].get('clientId');
            if( $('.boa').attr('href').includes('ezzing')){
                $('.boa').attr('href',"https://engie-simulator.ezzing.com?int-ga="+gaId);
            }
        });    
    </#if> */
  return (
    <>
      {link && link !== "#" ? (
        <Link
          to={link}
          target={newPage ? "_blank" : "_self"}
          onClick={() =>
            useGa?.value && dataLayerPush(dataLayerStr2Obj(`{${useGa.content.gaScript.value}}`))
          }
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </>
  );
};

const BadgeWidget = ({ data }) => {
  const { mainImg, mainDescription, badgeGradient, badgeLink, useGa } = data;

  return (
    <section className="section badge-widget" style={{ background: badgeGradient?.value }}>
      <BadgeBox link={badgeLink?.value} newPage={badgeLink?.content?.newPage?.value} useGa={useGa}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 badge-widget__img-container text-center text-lg-right">
              {mainImg?.node && (
                <GatsbyImage
                  className="badge-widget__img"
                  image={getImage(mainImg.node)}
                  alt="Immagine badge"
                />
              )}
            </div>
            <div className="col-lg-8">
              {mainDescription?.value && (
                <div
                  className="badge-widget__text"
                  dangerouslySetInnerHTML={{ __html: mainDescription.value }}
                />
              )}
            </div>
          </div>
        </div>
      </BadgeBox>
    </section>
  );
};

export default withPreview(BadgeWidget);
export const fragment = graphql`
  fragment BadgeWidgetFragment on LiferayBadgeWidget {
    liferayFields {
      siteId
      articleId
    }
    mainImg {
      node {
        gatsbyImageData(width: 200)
      }
    }
    mainDescription {
      value
    }
    badgeGradient {
      value
    }
    badgeLink {
      value
      content {
        newPage {
          value
        }
      }
    }
    useGa {
      value
      content {
        gaScript {
          value
        }
      }
    }
  }
`;

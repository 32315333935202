import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";

const ElencoDocumenti = ({ data }) => {
  const { title, document } = data;
  // console.log(title);
  return (
    <section className="section elenco-documenti wrapper-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="section__title" style={{ color: title.content?.colorTitle?.value }}>
              {title.value}
            </h2>
          </div>
        </div>
        {document.length ? (
          <div className="row">
            <div className="col-md-12">
              {document
                .filter((item) => item.content)
                .map((item, i) => (
                  <div className="elenco-documenti__document" key={i}>
                    <a
                      href={
                        item?.content?.placeholder?.value
                          ? item?.content?.placeholder?.value
                              ?.replace(
                                "@condizioniGenerali",
                                item?.content?.prodottoCRM?.urlDocCondizioniGenerali
                              )
                              .replace(
                                "@condizioniParticolari",
                                item?.content?.prodottoCRM?.urlDocCondizioniParticolari
                              )
                          : item?.content?.documentFile?.node.publicURL
                      }
                      target="_blank"
                      rel="noreferrer"
                      title={item.value}
                      className="elenco-documenti__link"
                    >
                      <div className="elenco-documenti__extension">
                        {item?.content?.documentFile?.node.encodingFormat
                          ? item?.content?.documentFile?.node.encodingFormat
                              ?.split("/")
                              ?.reverse()?.[0] || ""
                          : "PDF"}
                      </div>
                      {item.value}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};
export default withPreview(ElencoDocumenti);
export const fragment = graphql`
  fragment ElencoDocumentiFragment on LiferayElencoDocumenti {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        colorTitle {
          value
        }
      }
    }
    document {
      value
      content {
        prodottoCRM {
          urlDocCondizioniGenerali
          urlDocCondizioniParticolari
        }
        placeholder {
          value
        }
        documentFile {
          node {
            publicURL
            encodingFormat
          }
        }
      }
    }
  }
`;
